<template>
    <span>
        {{ timeRemaining }}
    </span>
</template>

<script setup>
const props = defineProps({
    date: {
        type: [Date, String],
        required: true,
    }
})

const timeRemaining = computed(() => {
    return untillDate(props.date)
})
</script>
